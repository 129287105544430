import { Controller } from "@hotwired/stimulus"

// converted from an old JS file
// could use some improvements, but code was kept roughly the same
export default class extends Controller {
  static values = {
    deviseTimeoutIn: Number,
    loggedIn: Boolean,
    timeUntilLogoutPath: String,
    resetLogoutTimePath: String,
    logoutPath: String
  }

  static targets = ['secondsRemaining', 'stayLoggedIn']

  connect() {
    if (this.loggedInValue) {
      this.timeoutLength = this.deviseTimeoutInValue || 600;

      // warn the user x seconds before timeout
      this.warning = 60;

      // will be used to hold warning countdown interval
      this.interval = null;
      this.timeout = null

      // grab some stuff from the dom
      this.modal = $( this.element );
      this.countdown = $( this.secondsRemainingTarget );
      this.stay = $( this.stayLoggedInTarget );

      // stay logged in when clicking the stay logged in button
      this.stay.click( this.stayLoggedIn.bind(this) );

      // start the session timer
      this.startTimer();
    }
  }

  disconnect() {
    super.disconnect();
    clearInterval(this.interval)
    clearTimeout(this.timeout)
  }

  startTimer(){
    this.timeout = setTimeout(this.checkWarning.bind(this), (this.timeoutLength - this.warning + 2) * 1000);
  }

  async checkWarning(){
    const response = await fetch(this.timeUntilLogoutPathValue)

    if (response.ok) {
      const data = await response.json()

      if ( data.remaining <= this.warning ) {
        this.showWarning( data.remaining );
      }
      else {
        this.timeoutLength = data.remaining;

        this.startTimer();
      }
    } else {
      this.fail(response)
    }
  }

  showWarning(remaining){
    let display = remaining;

    this.countdown.text( display );

    this.modal.modal({
      keyboard: false,
      backdrop: 'static'
    });

    this.interval = setInterval(() => {
      display --;

      this.countdown.text( display );

      if ( display <= 0 ) {
        clearInterval( this.interval );

        this.makeFinalCheckAndRedirect();
      }

    }, 1000);

  }

  async makeFinalCheckAndRedirect(){
    const response = await fetch(this.timeUntilLogoutPathValue)

    if (response.ok) {
      const data = await response.json()

      if ( data.remaining <= 2 ) {
        document.location.href = this.logoutPathValue;
      } else {
        this.closeModal();
        this.timeoutLength = data.remaining;
        this.startTimer();
      }
    } else {
      this.fail(response)
    }
  }

  stayLoggedIn(){
    if ( this.interval ) {
      clearInterval( this.interval );
    }

    this.stay.text( 'Loading...' );
    this.stay.attr( 'disabled', 'disabled' );

    const response = fetch(this.resetLogoutTimePathValue)

    if (!response.ok) {
      this.fail(response)
    }

    this.closeModal();

    this.timeoutLength = this.deviseTimeoutInValue || 600;

    this.startTimer();
  }

  closeModal(){

    this.modal.modal( 'hide' );

    this.stay.text( 'Stay Logged In' );
    this.stay.removeAttr( 'disabled' );

  }

  fail(response){
    // if it failed because we don't have access, logout
    if ( response && response.status === 401 ) {
      document.location.href = this.logoutPathValue
    }
  }
}