import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  colorValidation = {}

  static values = {
    context: String,
    productLineKey: String
  }

  static targets = [
    'linkColorRadios',
    'cardColorRadios',
    'cardTransparencyRadios',
    'primaryInterfaceColorBgClass',
    'primaryInterfaceColorClass',
    'arrowLeftClass',
    'textCheckmarkColorClass',
    'linkColorClass',
    'corporateOverrideRadios',
    'corporateOverrideForm',
  ]

  connect() {
    let targetArray = [this.linkColorRadiosTarget, this.cardColorRadiosTarget, this.cardTransparencyRadiosTarget,]

    if (this.hasCorporateOverrideRadiosTarget) {
      targetArray.push(this.corporateOverrideRadiosTarget)
    }

    targetArray.forEach(e => this.triggerEvent('change', e));
  }

  colorValidated({ detail: { isValid, fieldName } }) {
    this.colorValidation[fieldName] = isValid
  }

  updatePreview(e) {
    const field = e['currentTarget']

    if (field.dataset.fieldName === 'primaryInterfaceColor') {
      const color = this.getColor('primary_interface_color')
      this.primaryInterfaceColorClassTargets.forEach(e => e.style['color'] = color)
      this.primaryInterfaceColorBgClassTargets.forEach(e => e.style['background-color'] = color)
      this.arrowLeftClassTargets.forEach(e => e.style['border-right-color'] = color)
    } else if (field.dataset.fieldName === 'textCheckmarkColor') {
      const color = this.getCheckedRadioValue(field) === 'light' ? '#FFFFFF' : '#333333'
      this.textCheckmarkColorClassTargets.forEach(e => e.style['color'] = color)
      // The checkmark is an '::after' pseudo element, so we can't set a style on it directly
      var styleEl = document.head.appendChild(document.createElement("style"))
      styleEl.innerText = `.checkbox-preview .square-inner:after {border-color: ${color} !important;}`
    } else if (field.dataset.fieldName === 'linkColor') {
      const color = this.getCheckedRadioValue(field) === 'true' ? this.getColor('primary_interface_color') : this.getColor('custom_link_color')
      this.linkColorClassTargets.forEach(e => e.style['color'] = color)
    } else if (field.dataset.fieldName === 'inlineFeedbackColor') {
      const color = this.getColor('inline_feedback_color')
      this.inlineFeedbackColorBgClassTargets.forEach(e => e.style['background-color'] = color)
    }
  }

  getColor(name) {
    return '#' + document.querySelector(`.color-input-section#${name}_controls input`).value
  }

  updateFeedbackPreviews(color){
    $('.feedback-color-bg').css('background-color', '#'+color)
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()
    const $submit = $(':submit')

    $submit.val($submit.attr('data-disable-with')).prop('disabled', true)

    if (Object.values(this.colorValidation).reduce((allValid, valid) => allValid && valid, true)) {
      const $form = $('#healthaware_colors_and_appearance_form')
      $submit.off('submit');
      $form.submit();
    } else {
      $submit.val($submit.attr('data-enable-with')).prop('disabled', false)
    }
  }

  toggleDefaultCustomField(e) {
    // The selected radio button value (true means using default)
    const radioValue = this.getCheckedRadioValue(e['currentTarget']) === 'true'

    if (radioValue) {
      e['currentTarget'].nextSibling.classList.add('hide')
    } else {
      e['currentTarget'].nextSibling.classList.remove('hide')
    }
  }

  toggleNameField(e) {
    // The selected radio button value (true means using default)
    const radioValue = this.getCheckedRadioValue(e['currentTarget']) === 'true'
    const nameInput = e['currentTarget'].querySelector('input[type="text"]')


    if (radioValue) {
      nameInput.disabled = 'disabled'
      nameInput.value = nameInput.dataset.default_value
    } else {
      nameInput.disabled = ''
    }

    this.triggerEvent('change', nameInput)
  }

  triggerEvent(eventName, element) {
    const event = new Event(eventName);
    element.dispatchEvent(event);
  }

  getCheckedRadioValue(element) {
    return [...element.querySelectorAll('input[type="radio"]')].find(i=>i.checked).value
  }

  toggleCorporateOverride() {
    this.corporateOverrideRadiosTargets.forEach(r => {
      if(r.checked === true) {
        // If the value of the checked radio button is true, corporate override is active
        r.value === 'true' ? this.corporateOverrideFormTarget.classList.remove('hide') : this.corporateOverrideFormTarget.classList.add('hide');
      }
    })
  }

}
