import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "userAgreementPresentationRadios", "userAgreementPresentationExamples", "userAgreement",
    "userAgreementConsentRadios", "userAgreementConsentExamples", "userAgreementConsentLabel",
    "useDefaultUserAgreementContentRadios","defaultUserAgreementContent", "customUserAgreementContent",
    "useDefaultUserAgreementStatementRadios", "userAgreementStatement","marketingOptInConsentRadios",
    "marketingOptInConsentExamples", "marketingOptInLabelContainer", "emailsAreOperational", "phoneOptInConsentRadios",
    "phoneOptInConsentExamples", "phoneOptInLabelContainer", "marketingOptInLabelRadios", "marketingOptInLabel",
    "phoneOptInLabelRadios", "phoneOptInLabel", "corporateOverrideRadios"
  ]
  static classes = [ "hide" ]
  static values = {
    productLine: String,
    isEnglish: Boolean
  }

  initialize() {
    this.toggleCorporateOverride();
  }

  connect() {
    this.toggleAgreementPresentationExamples()
    this.toggleAgreementConsentExamples()
    this.toggleAgreementContent()
    this.toggleAgreementStatement()
    this.toggleMarketingOptInConsent()
    this.toggleMarketingOptInLabel()
    this.togglePhoneOptInConsent()
    this.togglePhoneOptInLabel()
  }

  toggleAgreementPresentationExamples() {
    const selected = this.userAgreementPresentationRadiosTargets.find(radio => radio.checked).value

    this.userAgreementPresentationExamplesTargets.forEach((element) => {
      element.classList.add(this.hideClass)
    })

    document.getElementById(selected).classList.remove(this.hideClass);

    if (selected == 'no_user_agreement') {
      this.userAgreementTarget.classList.add(this.hideClass)
    } else {
      this.userAgreementTarget.classList.remove(this.hideClass)
    }
  }

  toggleAgreementConsentExamples() {
    const selected = this.userAgreementConsentRadiosTargets.find(radio => radio.checked).value

    this.userAgreementConsentLabelTarget.classList.add(this.hideClass);
    this.userAgreementConsentExamplesTargets.forEach((element) => {
        element.classList.add(this.hideClass)
    })

    document.getElementById(selected).classList.remove(this.hideClass);

    if (selected === 'implicit_consent') {
      this.userAgreementConsentLabelTarget.classList.remove(this.hideClass);
    }
  }

  toggleAgreementContent() {
    const selected = this.useDefaultUserAgreementContentRadiosTargets.find(radio => radio.checked).value

    if (selected == 'true') {
      this.customUserAgreementContentTarget.classList.add(this.hideClass)
      this.defaultUserAgreementContentTarget.classList.remove(this.hideClass)
    } else if (selected == 'false') {
      this.defaultUserAgreementContentTarget.classList.add(this.hideClass)
      this.customUserAgreementContentTarget.classList.remove(this.hideClass)
    }
  }

  toggleAgreementStatement() {
    const selected = this.useDefaultUserAgreementStatementRadiosTargets.find(radio => radio.checked).value

    if (selected == 'true') {
      if (this.productLineValue === 'mh') {
        this.userAgreementStatementTarget.value = 'I have read, understood and agree to the {{Disclaimer, Privacy Policy, and Terms and Conditions}}'
      } else if (this.productLineValue === 'ha') {
        if (this.isEnglishValue) {
          this.userAgreementStatementTarget.value = 'I have read, understood and agree to the {{End User Agreement}}';
        } else {
          this.userAgreementStatementTarget.value = 'He leído, comprendido y acepto el {{Acuerdo del Usuario Final.}}';
        }
      }

      this.userAgreementStatementTarget.disabled = true
      this.triggerChangeEvent(this.userAgreementStatementTarget);
    } else if (selected == 'false') {
      this.userAgreementStatementTarget.disabled = false
    }
  }

  toggleMarketingOptInConsent() {
    const selected = this.marketingOptInConsentRadiosTargets.find(radio => radio.checked).value

    this.toggleOptInExamples(this.marketingOptInConsentExamplesTarget, selected);

    if (selected === 'opt_in_not_required' || selected === 'no_opt_in') {
      this.marketingOptInLabelContainerTarget.classList.add(this.hideClass)
    } else {
      this.marketingOptInLabelContainerTarget.classList.remove(this.hideClass)
    }

    if (selected === 'implied_consent_with_opt_out' || selected === 'opt_in') {
      this.emailsAreOperationalTarget.classList.remove(this.hideClass)
    } else {
      this.emailsAreOperationalTarget.classList.add(this.hideClass)
    }
  }

  toggleMarketingOptInLabel() {
    const selected = this.marketingOptInLabelRadiosTargets.find(radio => radio.checked).value

    if (selected == 'true') {
      if (this.isEnglishValue) {
        this.marketingOptInLabelTarget.value = 'I opt-in to receive marketing communications';
      } else {
        this.marketingOptInLabelTarget.value = 'Acepto recibir comunicaciones de marketing';
      }

      this.marketingOptInLabelTarget.disabled = true
      this.triggerChangeEvent(this.marketingOptInLabelTarget);
    } else if (selected == 'false') {
      this.marketingOptInLabelTarget.disabled = false
    }
  }

  togglePhoneOptInConsent() {
    const selected = this.phoneOptInConsentRadiosTargets.find(radio => radio.checked).value

    this.toggleOptInExamples(this.phoneOptInConsentExamplesTarget, selected);

    if (selected === 'opt_in_not_required' || selected === 'no_opt_in') {
      this.phoneOptInLabelContainerTarget.classList.add(this.hideClass)
    } else {
      this.phoneOptInLabelContainerTarget.classList.remove(this.hideClass)
    }
  }

  togglePhoneOptInLabel() {
    const selected = this.phoneOptInLabelRadiosTargets.find(radio => radio.checked).value

    if (selected == 'true') {
      if (this.productLineValue === 'mh') {
        this.phoneOptInLabelTarget.value = 'I agree to receive a call about my results';
      } else if (this.productLineValue === 'ha') {
        if (this.isEnglishValue) {
          this.phoneOptInLabelTarget.value = 'I authorize you to contact me about my results';
        } else {
          this.phoneOptInLabelTarget.value = 'Autorizo que se pongan en contacto conmigo acerca de mis resultados';
        }
      }

      this.phoneOptInLabelTarget.disabled = true
      this.triggerChangeEvent(this.phoneOptInLabelTarget);
    } else if (selected == 'false') {
      this.phoneOptInLabelTarget.disabled = false
    }
  }

  toggleOptInExamples(parent, selected) {
    Array.from(parent.children).forEach((element) => {
      element.classList.add(this.hideClass)
    })

    if (this.productLineValue === 'ha' && (selected === 'opt_in_not_required' || selected === 'no_opt_in')) {
      parent.querySelector('.no-checkbox').classList.remove(this.hideClass)
    } else if (selected === 'implied_consent_with_opt_out') {
      parent.querySelector('.checked').classList.remove(this.hideClass)
    } else if(selected === 'opt_in') {
      parent.querySelector('.unchecked').classList.remove(this.hideClass)
    } else if(selected === 'implied_consent_required') {
      parent.querySelector('.checked').classList.remove(this.hideClass)
      parent.querySelector('.required-text').classList.remove(this.hideClass)
    } else if(selected === 'opt_in_required') {
      parent.querySelector('.unchecked').classList.remove(this.hideClass);
      parent.querySelector('.required-text').classList.remove(this.hideClass)
    }
  }

  triggerChangeEvent(element) {
    const event = new Event('change');
    element.dispatchEvent(event);
  }

  toggleCorporateOverride() {
    if (!this.hasCorporateOverrideRadiosTarget) return;
    const selected = $(this.corporateOverrideRadiosTarget).filter(':checked').val();

    if (selected === 'true') {
      $('.corporate-override').removeClass('hide');
    } else {
      $('.corporate-override').addClass('hide');
    }
  }
}
